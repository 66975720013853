import React, {Component} from 'react';
import "../styling/Projects.css";



export class Projects extends Component{
    render() {
        return (

            <div id="projects">
                <div>tsumami</div>
                <div>konami</div>
                <div>weathermap</div>
                <div>java contact manager</div>
            </div>

    )
    }
}



