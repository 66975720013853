import '../styling/Contact.css';
import yoshiProfilePic from '../img/ryan.png'
import emailjs from '@emailjs/browser';
import React, {useEffect, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { reCaptcha_siteKey } from '../apiKeys';








export const Contact = () => {

    const [inputState, setInputState] = useState({name: '', email: '', message: ''});

    function handleChange(evt) {
        const value = evt.target.value;
        setInputState({
            ...inputState,
            [evt.target.name]: value
        });
    }

    function onSubmitButtonPress(){
            // TODO: need to target button using virtual DOM for a sweet css effect.
        //    onclick, make button look like it got pressed, then reset to original style
            const submitButton = document.getElementById("submitButton");
            submitButton.style.boxShadow = 'inset 0px 9px 15px 0px black';
            submitButton.style.border = '1px solid black'
            submitButton.style.boxShadow = 'inset 0px 9px 15px 0px black';
        }

        function onSubmitButtonRelease(){
            const submitButton = document.getElementById("submitButton");
            submitButton.style.boxShadow = 'inset 0px -4px 15px 0px black';
        }

        async function activateSubmitButton(){
            if(this.state.isVerified){
                let submitBtn = document.getElementById("submitButton")
                submitBtn.setAttribute("disabled","")
            }
        }

    const recaptchaRef = React.createRef();

        const form = useRef();
        const sendEmail = (e) => {
            e.preventDefault();
            emailjs.sendForm('emailJS_API', 'template_v1', form.current, '2t7z2dIDEmHxJdPXm')
                .then((result) => {
                    console.log(result.text);
                    console.log("message sent");
                    setInputState({name: '', email: '', message: ''});
                    window.grecaptcha.reset();
                }, (error) => {
                    console.log(error.text);
                });
        };

        return (

            <div id="contact-background">
            <main id="main-container">
                    <div id="infoContainer">
                        {/*<div id="container-etc"></div>*/}
                        <form ref={form} onSubmit={sendEmail}>
                            <div id="outer-profile-img-box">
                                <div id="profile-img-box">
                                    <img id="yoshi-img" src={yoshiProfilePic} alt="img" />
                                </div>
                            </div>

                            <br/><br/>
                            <div id="about-me-text">
                                Hi There! Send me an email and I will get right back to you. <br/> Mahalo!
                            </div>
                            <br/><br/>
                            <div id="email-container">
                                <span>
                                    <label htmlFor="name">Name </label>
                                    <input className="input-form-lengths" type="text" name="name"  value={inputState.name} onChange={handleChange} required={true}/>
                                </span>
                                    <br/><br/>
                                    <span>
                                    <label htmlFor="email">Email &nbsp;</label>
                                    <input className="input-form-lengths" type="text" name="email"  value={inputState.email} onChange={handleChange} required={true} />
                                </span>
                                    <br/><br/>
                                    <span>
                                    <label htmlFor="message">Message &nbsp;</label>
                                    <textarea id="message-area" name="message" value={inputState.message} onChange={handleChange} required={true}/>
                                </span>
                                    <br/><br/>
                                <ReCAPTCHA
                                    name = "recaptcha"
                                    id = "recaptcha"
                                    onExpired={() => {
                                        recaptchaRef.current.reset(); // here
                                    }}
                                    ref={recaptchaRef}
                                    sitekey= {reCaptcha_siteKey}
                                    render= { "explicit" }
                                    theme= { "dark" }
                                />
                                <span id="submit-button-wrapper">
                                    <input id ="submitButton" onTouchStart={onSubmitButtonPress} onTouchEndCapture={onSubmitButtonPress} onMouseDown={onSubmitButtonPress} onMouseUp={onSubmitButtonRelease} type="submit" disabled=""/>
                                </span>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
    )
    }


